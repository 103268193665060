import InsertEmoticonOutlinedIcon from '@mui/icons-material/InsertEmoticonOutlined'

const support = {
    id: 'support',
    title: 'Support',
    type: 'group',
    children: [
        {
            id: 'sample-page',
            title: 'Sample Page',
            type: 'item',
            url: '/sample-page',
            icon: InsertEmoticonOutlinedIcon,
        },
        {
            id: 'documentation',
            title: 'Documentation',
            type: 'item',
            url: '#',
            icon: InsertEmoticonOutlinedIcon,
            external: true,
            target: '_self',
        },
    ],
}

export default support
