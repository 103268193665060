import AppsIcon from '@mui/icons-material/Apps'

const dashboard = {
    id: 'group-dashboard',
    title: 'Navigation',
    type: 'group',
    children: [
        {
            id: 'dashboard',
            title: 'Dashboard',
            type: 'item',
            url: '/',
            icon: AppsIcon,
        },
    ],
}

export default dashboard
