import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'
import { Organisation, OrganisationStats, Project } from '@/api/backend.types.ts'
import { ColorBlindMode, DefaultColorBlindMode } from '@/themes/daltonize.ts'

interface AppState {
    organisations: Organisation[] | null
    setOrganisations: (organisations: Organisation[] | null) => void

    projects: Project[] | null
    setProjects: (projects: Project[] | null) => void

    activeOrganisation: Organisation | null
    setActiveOrganisation: (organisation: Organisation | null) => void

    activeProject: Project | null
    setActiveProject: (project: Project | null) => void

    organisationStats: OrganisationStats | null
    setOrganisationStats: (stats: OrganisationStats | null) => void

    colorBlindMode: ColorBlindMode
    setColorBlindMode: (mode: ColorBlindMode) => void
}

export const useAppStore = create<AppState>()(
    persist(
        (set, get) => {
            return {
                organisations: null,
                setOrganisations: (organisations) => {
                    set({ organisations })
                    if (!get().activeOrganisation && organisations?.length) {
                        set({ activeOrganisation: organisations[0] })
                    }
                },

                projects: null,
                setProjects: (projects) => set({ projects }),

                activeOrganisation: null,
                setActiveOrganisation: (organisation) => set({ activeOrganisation: organisation }),

                activeProject: null,
                setActiveProject: (project) => set({ activeProject: project }),

                organisationStats: null,
                setOrganisationStats: (stats) => set({ organisationStats: stats }),

                colorBlindMode: DefaultColorBlindMode,
                setColorBlindMode: (mode) => set({ colorBlindMode: mode }),
            }
        },
        {
            name: 'app',
            partialize: (state) => ({
                activeOrganisation: state.activeOrganisation,
                activeProject: state.activeProject,
                organisationStats: state.organisationStats,
                colorBlindMode: state.colorBlindMode,
            }),
            storage: createJSONStorage(() => sessionStorage), // only store per-tab
        }
    )
)

// useAppStore.subscribe(
//     (state) => state.activeOrganisation,
//     (newOrganisation, previousOrganisation) => {
//         if (newOrganisation !== previousOrganisation) {
//             useAppStore.getState().updateOrganisationStats()
//         }
//     }
// )
