import Box from '@mui/material/Box'
import MainCard from '@/components/MainCard'
import React from 'react'

export default function AuthCard({ children }: { children: React.ReactNode }) {
    return (
        <MainCard
            sx={{
                maxWidth: { xs: 400, lg: 475 },
                margin: { xs: 2.5, md: 3 },
                '& > *': { flexGrow: 1, flexBasis: '50%' },
            }}
            content={false}
        >
            <Box sx={{ p: { xs: 2, sm: 3, md: 4, xl: 5 } }}>{children}</Box>
        </MainCard>
    )
}
