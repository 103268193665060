import { Link } from 'react-router'
import { ButtonBase } from '@mui/material'
import Stack from '@mui/material/Stack'
import Chip from '@mui/material/Chip'
import Logo from './LogoMain'

const LogoSection = ({ sx, to }: { sx?: object; to?: string }) => {
    let mode = import.meta.env.MODE
    switch (import.meta.env.MODE) {
        case 'development':
            mode = 'dev'
            break
        case 'production':
            mode = 'live'
            break
    }
    return (
        <ButtonBase disableRipple component={Link} to={to ?? '/'} sx={sx}>
            <Stack direction="row" spacing={1} alignItems="center">
                <Logo />
                <Chip
                    label={mode}
                    variant="outlined"
                    size="small"
                    color="secondary"
                    sx={{ mt: 0.5, ml: 1, fontSize: '0.725rem', height: 20, '& .MuiChip-label': { px: 0.5 } }}
                />
            </Stack>
        </ButtonBase>
    )
}

export default LogoSection
