import { useMutation, useQuery, useQueryClient, UseQueryOptions, UseQueryResult } from '@tanstack/react-query'
import {
    ActionListResponse,
    ApiKeyResponse,
    AuthTokenResponse,
    BooleanResponse,
    DataRegionResponse,
    HealthCheckResponse,
    OrganisationListResponse,
    OrganisationResponse,
    OrganisationStatsResponse,
    OrganisationUpdateValidator,
    ProjectCreateValidator,
    ProjectListResponse,
    ProjectResponse,
    ProjectUpdateValidator,
    TraceListResponse,
    TraceResponse,
    UserAuthTokenResponse,
    UserEmailVerifyValidator,
    UserInviteListResponse,
    UserInviteValidator,
    UserListResponse,
    UserRegisterValidator,
    UserResponse,
    UserUpdateValidator,
} from '@/api/backend.types'
import { AuthApi, OrganisationApi, ProjectApi, SystemApi, UserApi } from '@/api/backend.client.ts'
import * as v from 'valibot'

export function createQueryHook<TData, TVariables = void>({
    queryKey,
    queryFn,
}: {
    queryKey: (variables: TVariables) => readonly unknown[]
    queryFn: (variables: TVariables) => Promise<TData>
}) {
    return (
        variables: TVariables,
        options?: Omit<UseQueryOptions<TData, unknown>, 'queryKey' | 'queryFn'>
    ): UseQueryResult<TData, unknown> => {
        return useQuery<TData, unknown>({
            queryKey: queryKey(variables),
            queryFn: () => queryFn(variables),
            ...options, // Spread additional options like `enabled`, `staleTime`, etc.
        })
    }
}

interface MutationOptions<TData, TVariables> {
    mutationFn: (variables: TVariables) => Promise<TData>
    onSuccess?: (data: TData, variables: TVariables) => void
    onError?: (error: unknown, variables: TVariables) => void
    invalidateKeys?: (variables: TVariables) => readonly unknown[][]
}

export function createMutationHook<TData, TVariables>({
    mutationFn,
    onSuccess,
    onError,
    invalidateKeys,
}: MutationOptions<TData, TVariables>) {
    return () => {
        const queryClient = useQueryClient()
        return useMutation<TData, unknown, TVariables>({
            mutationFn,
            onSuccess: (data, variables) => {
                if (invalidateKeys) {
                    invalidateKeys(variables).forEach((key) => queryClient.invalidateQueries({ queryKey: key }))
                }
                if (onSuccess) onSuccess(data, variables)
            },
            onError: (error, variables) => {
                if (onError) onError(error, variables)
            },
        })
    }
}

export const QueryKeys = {
    health: () => ['health'], // Simple key for health check
    dataRegions: () => ['dataRegions'],
    organisationsList: (page: number, perPage: number) => ['organisationsList', page, perPage],
    organisationStats: (organisationId: string) => ['organisationStats', organisationId, 'stats'],
    organisationProjects: (organisationId: string, page: number, perPage: number) => [
        'organisationProjects',
        organisationId,
        page,
        perPage,
    ],
    organisationUsers: (organisationId: string, page: number, perPage: number) => [
        'organisationUsers',
        organisationId,
        page,
        perPage,
    ],
    organisationInvites: (organisationId: string) => ['organisationInvites', organisationId],
    organisation: (organisationId: string) => ['organisation', organisationId],
    userProfile: (userId: string) => ['user', userId],
    project: (projectId: string) => ['project', projectId],
    projects: (page: number, perPage: number) => ['projects', page, perPage],
    actions: (projectId: string, page: number, perPage: number) => ['projectActions', projectId, page, perPage],
    tracesForAction: (projectId: string, actionId: string, page: number, perPage: number) => [
        'tracesForAction',
        projectId,
        actionId,
        page,
        perPage,
    ],
    tracesForProject: (projectId: string, page: number, perPage: number) => [
        'tracesForProject',
        projectId,
        page,
        perPage,
    ],
    trace: (projectId: string, traceId: string) => ['projectTrace', projectId, traceId],
}

export class SystemHooks {
    static useHealth = createQueryHook<HealthCheckResponse>({
        queryKey: () => QueryKeys.health(),
        queryFn: () => SystemApi.health(),
    })
    static useDataRegions = createQueryHook<DataRegionResponse>({
        queryKey: () => QueryKeys.dataRegions(),
        queryFn: () => SystemApi.dataRegions(),
    })
}

export class AuthHooks {
    static register = createMutationHook<UserAuthTokenResponse, v.InferInput<typeof UserRegisterValidator>>({
        mutationFn: (data) => AuthApi.register(data),
    })

    static verifyEmail = createMutationHook<UserAuthTokenResponse, v.InferInput<typeof UserEmailVerifyValidator>>({
        mutationFn: (data) => AuthApi.verifyEmail(data),
    })

    static login = createMutationHook<UserAuthTokenResponse | BooleanResponse, Parameters<typeof AuthApi.login>[0]>({
        mutationFn: (data) => AuthApi.login(data),
    })

    static logout = createMutationHook<BooleanResponse, void>({
        mutationFn: AuthApi.logout,
    })

    static refresh = createMutationHook<AuthTokenResponse, void>({
        mutationFn: AuthApi.refresh,
    })
}

export class UserHooks {
    static me = createQueryHook<UserResponse>({
        queryKey: () => QueryKeys.userProfile('me'),
        queryFn: () => UserApi.me(),
    })

    static updateMe = createMutationHook<UserResponse, v.InferInput<typeof UserUpdateValidator>>({
        mutationFn: (data) => UserApi.updateMe(data),
        invalidateKeys: () => [QueryKeys.userProfile('me')],
    })

    static getUser = createQueryHook<UserResponse, string>({
        queryKey: (userId) => QueryKeys.userProfile(userId),
        queryFn: (userId) => UserApi.getUser(userId),
    })
}

export class OrganisationHooks {
    static getOrganisations = createQueryHook<OrganisationListResponse, { page: number; perPage: number }>({
        queryKey: ({ page, perPage }) => QueryKeys.organisationsList(page, perPage),
        queryFn: ({ page, perPage }) => OrganisationApi.getOrganisations(page, perPage),
    })

    static getOrganisation = createQueryHook<OrganisationResponse, string>({
        queryKey: (organisationId) => QueryKeys.organisation(organisationId),
        queryFn: (organisationId) => OrganisationApi.getOrganisation(organisationId),
    })

    static getOrganisationStats = createQueryHook<OrganisationStatsResponse, string>({
        queryKey: (organisationId) => QueryKeys.organisationStats(organisationId),
        queryFn: (organisationId) => OrganisationApi.getOrganisationStats(organisationId),
    })

    static updateOrganisation = createMutationHook<
        OrganisationResponse,
        { organisationId: string; data: v.InferInput<typeof OrganisationUpdateValidator> }
    >({
        mutationFn: ({ organisationId, data }) => OrganisationApi.updateOrganisation(organisationId, data),
        invalidateKeys: (variables) => [
            QueryKeys.organisationStats(variables.organisationId), // Invalidate stats
            QueryKeys.project(variables.organisationId), // Invalidate another related query
        ],
    })

    static getOrganisationProjects = createQueryHook<
        ProjectListResponse,
        {
            organisationId: string
            page: number
            perPage: number
        }
    >({
        queryKey: ({ organisationId, page, perPage }) => QueryKeys.organisationProjects(organisationId, page, perPage),
        queryFn: ({ organisationId, page, perPage }) =>
            OrganisationApi.getOrganisationProjects(organisationId, page, perPage),
    })

    static getOrganisationUsers = createQueryHook<
        UserListResponse,
        {
            organisationId: string
            page: number
            perPage: number
        }
    >({
        queryKey: ({ organisationId, page, perPage }) => QueryKeys.organisationUsers(organisationId, page, perPage),
        queryFn: ({ organisationId, page, perPage }) =>
            OrganisationApi.getOrganisationUsers(organisationId, page, perPage),
    })

    static inviteUser = createMutationHook<
        BooleanResponse,
        {
            organisationId: string
            data: v.InferInput<typeof UserInviteValidator>
        }
    >({
        mutationFn: ({ organisationId, data }) => OrganisationApi.inviteUser(organisationId, data),
    })

    static getOrganisationInvites = createQueryHook<UserInviteListResponse, { organisationId: string }>({
        queryKey: ({ organisationId }) => QueryKeys.organisationInvites(organisationId),
        queryFn: ({ organisationId }) => OrganisationApi.getOrganisationInvites(organisationId),
    })

    static deleteOrganisationInvite = createMutationHook<
        BooleanResponse,
        {
            organisationId: string
            inviteId: string
        }
    >({
        mutationFn: ({ organisationId, inviteId }) =>
            OrganisationApi.deleteOrganisationInvite(organisationId, inviteId),
    })
}

export class ProjectHooks {
    static getProjects = createQueryHook<ProjectListResponse, { page: number; perPage: number }>({
        queryKey: ({ page, perPage }) => QueryKeys.projects(page, perPage),
        queryFn: ({ page, perPage }) => ProjectApi.getProjects(page, perPage),
    })

    static createProject = createMutationHook<ProjectResponse, v.InferInput<typeof ProjectCreateValidator>>({
        mutationFn: (data) => ProjectApi.createProject(data),
    })

    static getProject = createQueryHook<ProjectResponse, { projectId: string }>({
        queryKey: ({ projectId }) => QueryKeys.project(projectId),
        queryFn: ({ projectId }) => ProjectApi.getProject(projectId),
    })

    static updateProject = createMutationHook<
        ProjectResponse,
        {
            projectId: string
            data: v.InferInput<typeof ProjectUpdateValidator>
        }
    >({
        mutationFn: ({ projectId, data }) => ProjectApi.updateProject(projectId, data),
        invalidateKeys: (variables) => [QueryKeys.project(variables.projectId)],
    })

    static deleteProject = createMutationHook<BooleanResponse, { projectId: string }>({
        mutationFn: ({ projectId }) => ProjectApi.deleteProject(projectId),
        invalidateKeys: (variables) => [QueryKeys.project(variables.projectId)],
    })

    static generateApiKey = createMutationHook<ApiKeyResponse, { projectId: string }>({
        mutationFn: ({ projectId }) => ProjectApi.generateApiKey(projectId),
    })

    static getActions = createQueryHook<ActionListResponse, { projectId: string; page: number; perPage: number }>({
        queryKey: ({ projectId, page, perPage }) => QueryKeys.actions(projectId, page, perPage),
        queryFn: ({ projectId, page, perPage }) => ProjectApi.getActions(projectId, page, perPage),
    })

    static getTracesForProject = createQueryHook<
        TraceListResponse,
        {
            projectId: string
            page: number
            perPage: number
        }
    >({
        queryKey: ({ projectId, page, perPage }) => QueryKeys.tracesForProject(projectId, page, perPage),
        queryFn: ({ projectId, page, perPage }) => ProjectApi.getTracesForProject(projectId, page, perPage),
    })

    static getTracesForAction = createQueryHook<
        TraceListResponse,
        {
            projectId: string
            actionId: string
            page: number
            perPage: number
        }
    >({
        queryKey: ({ projectId, actionId, page, perPage }) =>
            QueryKeys.tracesForAction(projectId, actionId, page, perPage),
        queryFn: ({ projectId, actionId, page, perPage }) =>
            ProjectApi.getTracesForAction(projectId, actionId, page, perPage),
    })

    static getTrace = createQueryHook<TraceResponse, { projectId: string; traceId: string }>({
        queryKey: ({ projectId, traceId }) => QueryKeys.trace(projectId, traceId),
        queryFn: ({ projectId, traceId }) => ProjectApi.getTrace(projectId, traceId),
    })
}
