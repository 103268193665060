import { create } from 'zustand'
import { persist } from 'zustand/middleware'

export enum DeviceType {
    DESKTOP = 'DESKTOP',
    MOBILE = 'MOBILE',
}

interface MenuState {
    isDashboardDrawerOpened: boolean
    openedItem: string
    openedComponent: string
    openedHorizontalItem: string | null
    isComponentDrawerOpened: boolean
    toggleDrawer: () => void
    setDrawOpened: (isOpen: boolean) => void
    setOpenedItem: (item: string) => void
}

export const useMenuStore = create<MenuState>()(
    persist(
        (set) => ({
            isDashboardDrawerOpened: false,
            openedItem: 'dashboard',
            openedComponent: 'buttons',
            openedHorizontalItem: null,
            isComponentDrawerOpened: true,

            toggleDrawer: () =>
                set((state) => ({
                    isDashboardDrawerOpened: !state.isDashboardDrawerOpened,
                })),

            setOpenedItem: (openedItem: string) =>
                set(() => ({
                    openedItem,
                })),

            setDrawOpened: (isOpen: boolean) =>
                set(() => ({
                    isDashboardDrawerOpened: isOpen,
                })),
        }),
        {
            name: 'menu', // Unique name for localStorage key
            // If you only want to store certain fields, you can use partialize here:
            // partialize: (state) => ({ isDashboardDrawerOpened: state.isDashboardDrawerOpened, openedItem: state.openedItem }),
        }
    )
)
