import {
    ActionListResponse,
    ApiKeyResponse,
    AuthTokenResponse,
    BooleanResponse,
    DataRegionResponse,
    HealthCheckResponse,
    OrganisationListResponse,
    OrganisationResponse,
    OrganisationStatsResponse,
    OrganisationUpdateValidator,
    ProjectCreateValidator,
    ProjectListResponse,
    ProjectResponse,
    ProjectUpdateValidator,
    TraceListResponse,
    TraceResponse,
    UserAuthTokenResponse,
    UserEmailVerifyValidator,
    UserInviteListResponse,
    UserInviteValidator,
    UserListResponse,
    UserRegisterValidator,
    UserResponse,
    UserUpdateValidator,
} from '@/api/backend.types.ts'
import { ApiClient } from '@/api/client.ts'
import * as v from 'valibot'

export class SystemApi {
    static health() {
        return ApiClient.get<HealthCheckResponse>('/health')
    }

    static dataRegions() {
        return ApiClient.get<DataRegionResponse>('/regions')
    }
}

export class AuthApi {
    static register(data: v.InferInput<typeof UserRegisterValidator>): Promise<UserAuthTokenResponse> {
        return ApiClient.postJson<UserAuthTokenResponse>('/auth/register', v.parse(UserRegisterValidator, data))
    }

    static verifyEmail(data: v.InferInput<typeof UserEmailVerifyValidator>): Promise<UserAuthTokenResponse> {
        return ApiClient.postJson<UserAuthTokenResponse>('/auth/verify', v.parse(UserEmailVerifyValidator, data))
    }

    static login({
        email,
        password,
    }: {
        email: string
        password: string
    }): Promise<UserAuthTokenResponse | BooleanResponse> {
        return ApiClient.postJson<UserAuthTokenResponse | BooleanResponse>('/auth/login', { email, password })
    }

    static logout() {
        return ApiClient.postJson<BooleanResponse>('/auth/logout')
    }

    static refresh(): Promise<AuthTokenResponse> {
        return ApiClient.postJson<AuthTokenResponse>('/auth/refresh')
    }
}

export class UserApi {
    static me() {
        return ApiClient.get<UserResponse>('/users/me')
    }

    static updateMe(data: v.InferInput<typeof UserUpdateValidator>) {
        return ApiClient.putJson<UserResponse>('/users/me', v.parse(UserUpdateValidator, data))
    }

    static getUser(userId: string) {
        return ApiClient.get<UserResponse>(`/users/${userId}`)
    }
}

export class OrganisationApi {
    static getOrganisations(page = 1, perPage = 10) {
        return ApiClient.get<OrganisationListResponse>(`/organisations?page=${page}&perPage=${perPage}`)
    }

    static getOrganisation(organisationId: string) {
        return ApiClient.get<OrganisationResponse>(`/organisations/${organisationId}`)
    }

    static getOrganisationStats(organisationId: string) {
        return ApiClient.get<OrganisationStatsResponse>(`/organisations/${organisationId}/stats`)
    }

    static updateOrganisation(organisationId: string, data: v.InferInput<typeof OrganisationUpdateValidator>) {
        return ApiClient.putJson<OrganisationResponse>(
            `/organisations/${organisationId}`,
            v.parse(OrganisationUpdateValidator, data)
        )
    }

    static getOrganisationProjects(organisationId: string, page = 1, perPage = 10): Promise<ProjectListResponse> {
        return ApiClient.get<ProjectListResponse>(
            `/organisations/${organisationId}/projects?page=${page}&perPage=${perPage}`
        )
    }

    static getOrganisationUsers(organisationId: string, page = 1, perPage = 10): Promise<UserListResponse> {
        return ApiClient.get<UserListResponse>(`/organisations/${organisationId}/users?page=${page}&perPage=${perPage}`)
    }

    static inviteUser(
        organisationId: string,
        data: v.InferInput<typeof UserInviteValidator>
    ): Promise<BooleanResponse> {
        return ApiClient.postJson<BooleanResponse>(
            `/organisations/${organisationId}/users/invite`,
            v.parse(UserInviteValidator, data)
        )
    }

    static getOrganisationInvites(organisationId: string): Promise<UserInviteListResponse> {
        return ApiClient.get<UserInviteListResponse>(`/organisations/${organisationId}/users/invites`)
    }

    static deleteOrganisationInvite(organisationId: string, inviteId: string): Promise<BooleanResponse> {
        return ApiClient.delete<BooleanResponse>(`/organisations/${organisationId}/users/invites/${inviteId}`)
    }
}

export class ProjectApi {
    static getProjects(page = 1, perPage = 10): Promise<ProjectListResponse> {
        return ApiClient.get<ProjectListResponse>(`/projects?page=${page}&perPage=${perPage}`)
    }

    static createProject(data: v.InferInput<typeof ProjectCreateValidator>): Promise<ProjectResponse> {
        return ApiClient.postJson<ProjectResponse>('/projects', v.parse(ProjectCreateValidator, data))
    }

    static getProject(projectId: string) {
        return ApiClient.get<ProjectResponse>(`/projects/${projectId}`)
    }

    static updateProject(projectId: string, data: v.InferInput<typeof ProjectUpdateValidator>) {
        return ApiClient.putJson<ProjectResponse>(`/projects/${projectId}`, v.parse(ProjectUpdateValidator, data))
    }

    static deleteProject(projectId: string) {
        return ApiClient.delete<BooleanResponse>(`/projects/${projectId}`)
    }

    static generateApiKey(projectId: string) {
        return ApiClient.postJson<ApiKeyResponse>(`/projects/${projectId}/api-key`)
    }

    static getActions(projectId: string, page = 1, perPage = 10) {
        return ApiClient.get<ActionListResponse>(`/projects/${projectId}/actions?page=${page}&perPage=${perPage}`)
    }

    static getTracesForProject(projectId: string, page = 1, perPage = 10): Promise<TraceListResponse> {
        return ApiClient.get<TraceListResponse>(`/projects/${projectId}/traces?page=${page}&perPage=${perPage}`)
    }

    static getTracesForAction(projectId: string, actionId: string, page = 1, perPage = 10): Promise<TraceListResponse> {
        return ApiClient.get<TraceListResponse>(
            `/projects/${projectId}/actions/${actionId}?page=${page}&perPage=${perPage}`
        )
    }

    static getTrace(projectId: string, traceId: string): Promise<TraceResponse> {
        return ApiClient.get<TraceResponse>(`/projects/${projectId}/trace/${traceId}`)
    }
}
