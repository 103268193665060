import Box from '@mui/material/Box'
import AuthFooter from '@/components/cards/AuthFooter'
import Logo from '@/components/logo'
import AuthCard from './AuthCard'
import Grid2 from '@mui/material/Grid2'
import React from 'react'

export default function AuthWrapper({ children }: { children: React.ReactNode }) {
    return (
        <Box sx={{ minHeight: '100vh' }}>
            <Grid2 container direction="column" justifyContent="flex-end" sx={{ minHeight: '100vh' }}>
                <Grid2 size={12} sx={{ pl: 3, pt: 3 }}>
                    <Logo />
                </Grid2>
                <Grid2 size={12}>
                    <Grid2
                        size={12}
                        container
                        justifyContent="center"
                        alignItems="center"
                        sx={{
                            minHeight: {
                                xs: 'calc(100vh - 210px)',
                                sm: 'calc(100vh - 134px)',
                                md: 'calc(100vh - 112px)',
                            },
                        }}
                    >
                        <Grid2>
                            <AuthCard>{children}</AuthCard>
                        </Grid2>
                    </Grid2>
                </Grid2>
                <Grid2 size={12} sx={{ mb: 3, mt: 1 }}>
                    <AuthFooter />
                </Grid2>
            </Grid2>
        </Box>
    )
}
