const rgb2lms: Matrix3x3 = [
    [17.8824, 43.5161, 4.11935],
    [3.45565, 27.1554, 3.86714],
    [0.0299566, 0.184309, 1.46709],
]

export const ColorBlindModeOptions = ['none', 'protanopia', 'deuteranopia', 'tritanopia', 'monochromatic'] as const
export type ColorBlindMode = (typeof ColorBlindModeOptions)[number]
export const DefaultColorBlindMode: ColorBlindMode = 'none'

const lms2lms: Record<ColorBlindMode, Matrix3x3> = {
    none: [
        [1, 0, 0],
        [0, 1, 0],
        [0, 0, 1],
    ],
    protanopia: [
        [0, 2.02344, -2.52581],
        [0, 1, 0],
        [0, 0, 1],
    ],
    deuteranopia: [
        [1, 0, 0],
        [0.494207, 0, 1.24827],
        [0, 0, 1],
    ],
    tritanopia: [
        [1, 0, 0],
        [0, 1, 0],
        [-0.395913, 0.801109, 0],
    ],
    monochromatic: [
        [0, 0, 0],
        [0, 0, 0],
        [0, 0, 0],
    ],
}

const corrections: Matrix3x3 = [
    [0, 0, 0],
    [0.7, 1, 0],
    [0.7, 0, 1],
]

function dot(x: Matrix3, y: Matrix3) {
    return x[0] * y[0] + x[1] * y[1] + x[2] * y[2]
}

function hexToRgb(hex: string): Matrix3 {
    if (!/^#([0-9a-fA-F]{6})$/.test(hex)) {
        throw new Error('Invalid hex color format. Expected format: #rrggbb.')
    }

    const r = Math.max(0, Math.min(parseInt(hex.slice(1, 3), 16), 255))
    const g = parseInt(hex.slice(3, 5), 16)
    const b = parseInt(hex.slice(5, 7), 16)

    return [r, g, b]
}

function rgbToHex(rgb: Matrix3): string {
    return `#${rgb
        .map((value) => {
            if (value < 0 || value > 255) {
                throw new Error('RGB values must be between 0 and 255.')
            }
            return value.toString(16).padStart(2, '0')
        })
        .join('')}`
}

type Matrix3 = [number, number, number]
type Matrix3x3 = [Matrix3, Matrix3, Matrix3]

function inverse(a: Matrix3x3): Matrix3x3 {
    const a00 = a[0][0],
        a01 = a[0][1],
        a02 = a[0][2],
        a10 = a[1][0],
        a11 = a[1][1],
        a12 = a[1][2],
        a20 = a[2][0],
        a21 = a[2][1],
        a22 = a[2][2],
        b01 = a22 * a11 - a12 * a21,
        b11 = -a22 * a10 + a12 * a20,
        b21 = a21 * a10 - a11 * a20,
        det = 1.0 / (a00 * b01 + a01 * b11 + a02 * b21)

    return [
        [b01 * det, (-a22 * a01 + a02 * a21) * det, (a12 * a01 - a02 * a11) * det],
        [b11 * det, (a22 * a00 - a02 * a20) * det, (-a12 * a00 + a02 * a10) * det],
        [b21 * det, (-a21 * a00 + a01 * a20) * det, (a11 * a00 - a01 * a10) * det],
    ]
}

export function daltonize(hex: string, mode: ColorBlindMode): string {
    return rgbToHex(daltonizeRgb(hexToRgb(hex), mode))
}

export function daltonizeRgb(color: Matrix3, mode: ColorBlindMode): Matrix3 {
    if (mode === 'none') {
        return color
    } else if (mode === 'monochromatic') {
        // const hsl = rgbToHsl(color)
        // hsl[1] = 0
        // return hslToRgb(hsl)

        const l = Math.round(0.2126 * color[0] + 0.7152 * color[1] + 0.0722 * color[2])
        return [l, l, l]
    } else {
        const LMS: Matrix3 = [dot(color, rgb2lms[0]), dot(color, rgb2lms[1]), dot(color, rgb2lms[2])]
        const lms: Matrix3 = [dot(LMS, lms2lms[mode][0]), dot(LMS, lms2lms[mode][1]), dot(LMS, lms2lms[mode][2])]
        const lms2rgb = inverse(rgb2lms)
        let error: Matrix3 = [dot(lms, lms2rgb[0]), dot(lms, lms2rgb[1]), dot(lms, lms2rgb[2])]
        error = [color[0] - error[0], color[1] - error[1], color[2] - error[2]]
        const correction: Matrix3 = [dot(error, corrections[0]), dot(error, corrections[1]), dot(error, corrections[2])]
        return [
            Math.min(Math.max((color[0] + correction[0]) | 0, 0), 255),
            Math.min(Math.max((color[1] + correction[1]) | 0, 0), 255),
            Math.min(Math.max((color[2] + correction[2]) | 0, 0), 255),
        ]
    }
}

// function rgbToHsl(rgb: Matrix3): Matrix3 {
//     const r = rgb[0] / 255
//     const g = rgb[1] / 255
//     const b = rgb[2] / 255
//
//     const max = Math.max(r, g, b)
//     const min = Math.min(r, g, b)
//     let h = 0
//     let s = 0
//     const l = (max + min) / 2
//
//     if (max !== min) {
//         const d = max - min
//         s = l > 0.5 ? d / (2 - max - min) : d / (max + min)
//         switch (max) {
//             case r:
//                 h = (g - b) / d + (g < b ? 6 : 0)
//                 break
//             case g:
//                 h = (b - r) / d + 2
//                 break
//             case b:
//                 h = (r - g) / d + 4
//                 break
//         }
//         h /= 6
//     }
//
//     return [Math.round(h * 360), Math.round(s * 100), Math.round(l * 100)]
// }

// function hslToRgb(hsl: Matrix3): Matrix3 {
//     let [h, s, l] = hsl
//     h /= 360
//     s /= 100
//     l /= 100
//
//     const hueToRgb = (p: number, q: number, t: number): number => {
//         if (t < 0) t += 1
//         if (t > 1) t -= 1
//         if (t < 1 / 6) return p + (q - p) * 6 * t
//         if (t < 1 / 2) return q
//         if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6
//         return p
//     }
//
//     let r: number, g: number, b: number
//
//     if (s === 0) {
//         r = g = b = l // achromatic (gray)
//     } else {
//         const q = l < 0.5 ? l * (1 + s) : l + s - l * s
//         const p = 2 * l - q
//         r = hueToRgb(p, q, h + 1 / 3)
//         g = hueToRgb(p, q, h)
//         b = hueToRgb(p, q, h - 1 / 3)
//     }
//
//     return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)]
// }
