/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

const Logo = () => {
    return (
        <>
            <h1>{import.meta.env.VITE_APP_NAME}</h1>
        </>
    )
}

export default Logo
