import React, { useRef, useState } from 'react'
import { useTheme } from '@mui/material/styles'
import ButtonBase from '@mui/material/ButtonBase'
import CardContent from '@mui/material/CardContent'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Paper from '@mui/material/Paper'
import IconButton from '@mui/material/IconButton'
import Popper from '@mui/material/Popper'
import Stack from '@mui/material/Stack'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import ProfileTab from './ProfileTab'
import SettingTab from './SettingTab'
import Avatar from '@/components/@extended/Avatar'
import MainCard from '@/components/MainCard'
import Transitions from '@/components/@extended/Transitions'
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined'
import { useAuthStore } from '@/stores/AuthStore.tsx'
import { Grid2 } from '@mui/material'
import BusinessIcon from '@mui/icons-material/Business';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined'

// tab panel wrapper
interface TabPanelProps {
    children?: React.ReactNode
    value: number
    index: number
}

function TabPanel({ children, value, index, ...other }: TabPanelProps) {
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`profile-tabpanel-${index}`}
            aria-labelledby={`profile-tab-${index}`}
            {...other}
        >
            {value === index && children}
        </div>
    )
}

function a11yProps(index: number) {
    return {
        id: `profile-tab-${index}`,
        'aria-controls': `profile-tabpanel-${index}`,
    }
}

export default function Profile() {
    const theme = useTheme()

    const authState = useAuthStore()

    const anchorRef = useRef<HTMLButtonElement | null>(null)
    const [open, setOpen] = useState(false)
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen)
    }

    const handleClose = (event: MouseEvent | TouchEvent) => {
        if (anchorRef.current?.contains(event.target as Node)) {
            return
        }
        setOpen(false)
    }

    const [value, setValue] = useState(0)

    const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue)
    }

    const iconBackColorOpen = 'grey.100'

    return (
        <Box sx={{ flexShrink: 0, ml: 0.75 }}>
            <ButtonBase
                sx={{
                    p: 0.25,
                    bgcolor: open ? iconBackColorOpen : 'transparent',
                    borderRadius: 1,
                    '&:hover': { bgcolor: 'secondary.light' },
                    '&:focus-visible': { outline: `2px solid ${theme.palette.secondary.dark}`, outlineOffset: 2 },
                }}
                aria-label="open profile"
                ref={anchorRef}
                aria-controls={open ? 'profile-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
            >
                <Stack direction="row" spacing={1.25} alignItems="center" sx={{ p: 0.5 }}>
                    <Avatar size="medium">{authState.getUserName()}</Avatar>
                    <Typography variant="subtitle1" sx={{ textTransform: 'capitalize' }}>
                        {authState.getUserName()}
                    </Typography>
                </Stack>
            </ButtonBase>
            <Popper
                placement="bottom-end"
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 9],
                            },
                        },
                    ],
                }}
            >
                {({ TransitionProps }) => (
                    <Transitions direction="down" type="grow" position="top-right" in={open} {...TransitionProps}>
                        <Paper
                            sx={{
                                boxShadow: 1,
                                width: 290,
                                minWidth: 240,
                                maxWidth: { xs: 250, md: 290 },
                            }}
                        >
                            <ClickAwayListener onClickAway={handleClose}>
                                <MainCard elevation={0} content={false}>
                                    <CardContent sx={{ px: 2.5, pt: 3 }}>
                                        <Grid2 container justifyContent="space-between" alignItems="center">
                                            <Grid2>
                                                <Stack direction="row" spacing={1.25} alignItems="center">
                                                    <Avatar sx={{ width: 32, height: 32 }}>
                                                        {authState.getUserName()}
                                                    </Avatar>
                                                    <Stack>
                                                        <Typography variant="h6">{authState.getUserName()}</Typography>
                                                        <Typography variant="body2" color="text.secondary">
                                                            Administrator
                                                        </Typography>
                                                    </Stack>
                                                </Stack>
                                            </Grid2>
                                            <Grid2>
                                                <Tooltip title="Logout">
                                                    <IconButton size="large" sx={{ color: 'text.primary' }}>
                                                        <LogoutOutlinedIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid2>
                                        </Grid2>
                                    </CardContent>

                                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                        <Tabs
                                            variant="fullWidth"
                                            value={value}
                                            onChange={handleChange}
                                            aria-label="profile tabs"
                                        >
                                            <Tab
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    textTransform: 'capitalize',
                                                }}
                                                icon={
                                                    <PersonOutlineOutlinedIcon
                                                        style={{ marginBottom: 0, marginRight: '10px' }}
                                                    />
                                                }
                                                label="Profile"
                                                {...a11yProps(0)}
                                            />
                                            <Tab
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    textTransform: 'capitalize',
                                                }}
                                                icon={
                                                    <BusinessIcon
                                                        style={{ marginBottom: 0, marginRight: '10px' }}
                                                    />
                                                }
                                                label="Organisation"
                                                {...a11yProps(1)}
                                            />
                                        </Tabs>
                                    </Box>
                                    <TabPanel value={value} index={0}>
                                        <ProfileTab />
                                    </TabPanel>
                                    <TabPanel value={value} index={1}>
                                        <SettingTab />
                                    </TabPanel>
                                </MainCard>
                            </ClickAwayListener>
                        </Paper>
                    </Transitions>
                )}
            </Popper>
        </Box>
    )
}
