import InsertEmoticonOutlinedIcon from '@mui/icons-material/InsertEmoticonOutlined'

const utilities = {
    id: 'utilities',
    title: 'Utilities',
    type: 'group',
    children: [
        {
            id: 'util-typography',
            title: 'Typography',
            type: 'item',
            url: '/typography',
            icon: InsertEmoticonOutlinedIcon,
        },
        {
            id: 'util-color',
            title: 'Color',
            type: 'item',
            url: '/color',
            icon: InsertEmoticonOutlinedIcon,
        },
        {
            id: 'util-shadow',
            title: 'Shadow',
            type: 'item',
            url: '/shadow',
            icon: InsertEmoticonOutlinedIcon,
        },
        {
            id: 'flamegraph',
            title: 'Flame Graph',
            type: 'item',
            url: '/flamegraph',
            icon: InsertEmoticonOutlinedIcon,
        },
    ],
}

export default utilities
